<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
export default {
  data() {
    return {
      hasLogin: false
    }
  },
  methods: {
    checkLogin() {
      if (!this.hasLogin) {
        this.$router.push('/register')
      }
    }
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  },
}
</script>


<style lang="scss">
#app {
  background: #000;
}


@media(min-width:750px) {
  // #app {
  //   max-width: 750px;
  //   max-height: 1334px;
  // }

}
</style>
