import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)



const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   meta: {
  //     requireAuth: true, // 判斷是否需要登入
  //    },
  // },
  {
    path: '/:game_id/login',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/:game_id/game',
    name: 'Game',
    component: () => import('../views/Game/Game.vue'),
    meta: { requireAuth: true }
  },
  {
    name: '404',
    path: '/:game_id/*',
    component: () => import('../views/404page.vue')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async(to, from, next) => {
  // 看看 to 和 from 兩個 arguments 會吐回什麼訊息
  // console.log('to: ', to)
  // console.log('from: ', from)
  
  // 目的路由在meta上是否有設置requireAuth: true
  if (to.meta.requireAuth) {
    let game_id = location.pathname.split("/")[1]
    let info = localStorage.getItem(`${game_id}`)
    if (info) {
        next()
    } else {
      next( `/${game_id}/login`)
    }
  } else {
    next()
  }
})
export default router
